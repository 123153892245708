import logo from './logo.svg';
import './App.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Site under construction, check out these link in the meantime.
        </p>
        <FontAwesomeIcon icon="check-square" />

        <i class="fab fa-instagram"></i>
        <FontAwesomeIcon icon={['fab', 'apple']} />

        <a
          className="App-link"
          href="https://www.instagram.com/arvinhjt/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Instagram
        </a>
        <a
          className="App-link"
          href="https://www.linkedin.com/in/arvin-han-6745a2136/"
          target="_blank"
          rel="noopener noreferrer"
        >
          LinkedIn
        </a>
      </header>
    </div>
  );
}

export default App;
